import { defineStore } from 'pinia';
import { AssignedUserAccessLevel, ProcurementAccessLevel, SettingsAccessLevels } from '../interfaces/procurementAccessLevelTypes.util';
import { sharedState } from './shared_access_level';

//Handle Viewing Only
export const useProcurementViewAccessLevelStore = defineStore({
  id: 'ViewProcurementAccessLevels',
  
  state: sharedState, 

  actions: {
    getSettingsAccessLevels(){
        return this.settingsAccessLevels;
    },
    getProcurementAccessLevels(){
        return this.procurementAccessLevel;
    },
    getBasicInformation(){
      return this.basicInformation;
    },

    
    
    //set Basic Information
    setAccessLevelId(id:number){this.basicInformation.id = id;},
    setAccessLevelName(input:string){ this.basicInformation.accessLevelName = input;},
    setAccessLevelDesc(input:string){this.basicInformation.description = input;},

    //set Enable All Procurement Access
    setEnableAllProcurementAccess(status:boolean){
      for (const key in this.procurementAccessLevel) {
        this.procurementAccessLevel[key as keyof ProcurementAccessLevel] = status;
      }
    },
   
    //set Access for Requisitions
    setViewYourRequisitions(status: boolean){ this.procurementAccessLevel.viewYourRequisitions = status;},
    setManageRequisitions(status: boolean){this.procurementAccessLevel.manageRequisitions = status;},
    setViewStaffRequisitions(status: boolean){this.procurementAccessLevel.viewStaffRequisitions = status;},
    setManageStaffRequisitions(status: boolean){this.procurementAccessLevel.manageStaffRequisitions = status;},
  
    //Set Access for Suppliers
    setManageSuppliers(status: boolean){this.procurementAccessLevel.manageSuppliers = status;},
    setViewSuppliers(status:boolean){this.procurementAccessLevel.viewSuppliers = status;},
    
    //set Access for procurement manager
    setViewProcurementManager(status:boolean){this.procurementAccessLevel.viewProcurementManager =  status;},
    setManageProcurementManager(status: boolean){this.procurementAccessLevel.manageProcurementManager = status;},
    
     //Manage Setting Access
      //set Enable All settings Access
     setEnableAllSettings(status:boolean){
      for (const key in this.settingsAccessLevels) {
        this.settingsAccessLevels[key as keyof SettingsAccessLevels] = status;
      }
    },
    //Set Settings Access
    setViewAccessLevel(status:boolean){this.settingsAccessLevels.viewAccessLevel = status;},
    setManageAccessLevel(status:boolean){this.settingsAccessLevels.manageAccessLevel = status;},
    setViewCapApprover(status:boolean){this.settingsAccessLevels.viewCapApprover = status;},
    setManageCapApprover(status:boolean){this.settingsAccessLevels.manageCapApprover = status;},

     //Set Assigned Users
  setAssignedUsers(users:AssignedUserAccessLevel[]){
  this.assigned_users = users;
  },
 //set setting access levels
  setSettingsAccessLevels(data:SettingsAccessLevels){
    this.settingsAccessLevels = data;
  },
  setProcurementAccessLevel(data:ProcurementAccessLevel){
    this.procurementAccessLevel = data;
  },

  setLoginHint(data: string){this.loginHint = data;},

}, 
persist: true
});

