<template>
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Apps">
    <path id="Vector" d="M4.52665 7.35311C6.08605 7.35311 7.35018 6.08898 7.35018 4.52958C7.35018 2.97019 6.08605 1.70605 4.52665 1.70605C2.96726 1.70605 1.70312 2.97019 1.70312 4.52958C1.70312 6.08898 2.96726 7.35311 4.52665 7.35311Z" stroke="#101840" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_2" d="M12.2934 7.35311H13.7052C15.117 7.35311 15.8228 6.64723 15.8228 5.23547V3.8237C15.8228 2.41194 15.117 1.70605 13.7052 1.70605H12.2934C10.8817 1.70605 10.1758 2.41194 10.1758 3.8237V5.23547C10.1758 6.64723 10.8817 7.35311 12.2934 7.35311Z" stroke="#101840" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_3" d="M21.472 7.35311C23.0314 7.35311 24.2955 6.08898 24.2955 4.52958C24.2955 2.97019 23.0314 1.70605 21.472 1.70605C19.9126 1.70605 18.6484 2.97019 18.6484 4.52958C18.6484 6.08898 19.9126 7.35311 21.472 7.35311Z" stroke="#101840" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_4" d="M3.82077 15.8233H5.23254C6.6443 15.8233 7.35018 15.1174 7.35018 13.7057V12.2939C7.35018 10.8822 6.6443 10.1763 5.23254 10.1763H3.82077C2.40901 10.1763 1.70312 10.8822 1.70312 12.2939V13.7057C1.70312 15.1174 2.40901 15.8233 3.82077 15.8233Z" stroke="#101840" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_5" d="M12.9993 15.8233C14.5587 15.8233 15.8228 14.5592 15.8228 12.9998C15.8228 11.4404 14.5587 10.1763 12.9993 10.1763C11.4399 10.1763 10.1758 11.4404 10.1758 12.9998C10.1758 14.5592 11.4399 15.8233 12.9993 15.8233Z" stroke="#101840" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_6" d="M20.7661 15.8233H22.1778C23.5896 15.8233 24.2955 15.1174 24.2955 13.7057V12.2939C24.2955 10.8822 23.5896 10.1763 22.1778 10.1763H20.7661C19.3543 10.1763 18.6484 10.8822 18.6484 12.2939V13.7057C18.6484 15.1174 19.3543 15.8233 20.7661 15.8233Z" stroke="#101840" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_7" d="M4.52665 24.294C6.08605 24.294 7.35018 23.0299 7.35018 21.4705C7.35018 19.9111 6.08605 18.647 4.52665 18.647C2.96726 18.647 1.70312 19.9111 1.70312 21.4705C1.70312 23.0299 2.96726 24.294 4.52665 24.294Z" stroke="#101840" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_8" d="M12.2934 24.294H13.7052C15.117 24.294 15.8228 23.5881 15.8228 22.1764V20.7646C15.8228 19.3529 15.117 18.647 13.7052 18.647H12.2934C10.8817 18.647 10.1758 19.3529 10.1758 20.7646V22.1764C10.1758 23.5881 10.8817 24.294 12.2934 24.294Z" stroke="#101840" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_9" d="M21.472 24.294C23.0314 24.294 24.2955 23.0299 24.2955 21.4705C24.2955 19.9111 23.0314 18.647 21.472 18.647C19.9126 18.647 18.6484 19.9111 18.6484 21.4705C18.6484 23.0299 19.9126 24.294 21.472 24.294Z" stroke="#101840" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
</template>