<template>
  <div
    class="
      absolute
      sm:right-4
      right-0
      top-0
      bg-white
      w-fit
      pl-3
      pr-12
      py-2.5
      shadow-md
      z-10
      rounded
      N800
      P200
    "
  >
    <a
      href="javascript:void(0);"
      class="flex gap-2 mb-4"
      @click.prevent="viewEmployee()"
    >
      <img src="@/assets/profile.svg" class="self-center" alt="profile" />
      <p>My profile</p>
    </a>
    <a
      class="flex gap-2"
      href="javascript:void(0);"
      @click.prevent="userLogout()"
    >
      <img src="@/assets/logout.svg" alt="logout" />
      <p>Logout</p>
    </a>
  </div>
</template>
<script setup lang="ts">
import { useStore } from '@/store';
import { useProcurementViewAccessLevelStore } from '@/apps/procurement-service/store/store_procurement_access_levels';
import { getToken, logout } from '@/services/auth';
import { encodeUrlPath } from '@/helpers/utils';


const store = useStore();
const procStore = useProcurementViewAccessLevelStore();

const loginHint: string = procStore.loginHint;
const env = process.env;

const viewEmployee = () => {
  let encryptedId = encodeUrlPath(store?.user?.user_id?.toString());
  let hostedURL = env.VUE_APP_EMPLOYEE_URL;
  const token: string | null = getToken();
  location.href = `${hostedURL}view-employee/${encryptedId}/personal?app-token=${token}?login-hint=${loginHint}`;
};

function userLogout() {
  logout().then(() => {
    localStorage.removeItem('app-token');
    window.location.href = `${env.VUE_APP_SSO}/logout?login-hint=${loginHint}&account=${store?.user?.email}&redirect-url=${env.VUE_APP_BASE}login`;
  });

   localStorage.removeItem("persistComponent");
   procStore.$reset();
}
</script>
