import gql from 'graphql-tag';

export const GET_ALL_EMPLOYEES = gql`
query ListProcurementUsers {
  listProcurementUsers {
    user_id
    full_name
  }
}
`;

export const GET_ALL_DEPARTMENT = gql`
  query ListProcurmentDepartments {
    listProcurmentDepartments {
      id
      user_id
      department_name
      parent_department
      hod {
        user_id
        first_name
        last_name
        email
      }
    }
  }
`;

export const GET_ALL_PROJECTS = gql`
query ListProcurementProjects {
  listProcurementProjects {
    technical_manager_id
    project_name
    technical_manager {
      user_id
      first_name
      last_name
      email
    }
  }
}
`;

export const GET_ALL_CAPAPPROVER = gql`
  query ListCapApprovers($input: ListCapApproverInput) {
    listCapApprovers(input: $input) {
      count
      data {
        id
        created_by
        deleted_at
        lower_limit
        upper_limit
        user_id
        currency
        description
        user_info {
          full_name
        }
      }
    }
  }
`;

export const GET_ALL_REQUISITION = gql`
  query ListUsersRequests($input: ListUsersRequestsInput) {
    listUsersRequests(input: $input) {
      count
      data {
        id
        user_id
        reference_id
        item_name
        quantity
        justification
        is_draft
        currency_id
        currency
        category_id
        unit_price
        item_specification
        requisition_status_id
        request_type_id
        request_type
        request_status
        priority
        total_cost
        first_approver_id
        average_response_time
        dept_or_proj
        created_at
        attachments {
          file_name
          file_url
        }
      }
    }
  }
`;

export const GET_REQUEST_DETAILS = gql`
 query GetRequest($requestId: Int) {
    getRequest(requestId: $requestId) {
      created_at
      currency
      first_approver_id
      cap_approver_user_id
      id
      item_name
      item_specification
      justification
      priority
      quantity
      reference_id
      request_status
      total_cost
      unit_price
      category_id
      average_response_time
      user_info {
        first_name
        last_name
        email
        image_url
        country
        phone
        department {
          parent_department
        }
        position {
          position_name
        }
      }
      attachments {
        file_name
        file_url
        id
        requisition_id
      }
      approvals {
        order
        comment
        approved_at
        approver_id
        is_approved
        requisition_id
        approver {
          first_name
          last_name
          position {
            position_name
          }
          image_url
          user_id
          email
        }
      }
    }
  }
`;

export const GET_ALL_FILTER = gql`
  query FilterRequest(
    $filter: FilterInput
    $pagination: PaginationInput
    $userId: Int
  ) {
    filterRequest(filter: $filter, pagination: $pagination, user_id: $userId) {
      count
      data {
        user_info {
          first_name
          last_name
          phone
          email
          position {
            position_name
          }
          department {
            parent_department
          }
        }

        user_id
        unit_price
        request_type
        request_type_id
        request_status
        requisition_status_id
        reference_id
        quantity
        priority
        category_id
        justification
        item_specification
        item_name
        is_draft
        id
        currency
        created_at
        attachments {
          file_name
          file_url
          id
          requisition_id
        }
      }
    }
  }
`;

export const SEARCH_ITEM = gql`
  query SearchRequisition(
    $keyword: String
    $pagination: PaginationInput
    $userId: Int
  ) {
    searchRequisition(
      keyword: $keyword
      pagination: $pagination
      user_id: $userId
    ) {
      count
      data {
        user_info {
          first_name
          last_name
          phone
          email
          position {
            position_name
          }
          department {
            parent_department
          }
        }
        user_id
        unit_price

        request_type
        request_type_id
        request_status
        requisition_status_id
        reference_id
        quantity
        priority
        justification
        item_specification
        item_name
        is_draft
        id
        currency
        created_at
        attachments {
          file_name
          file_url
          id
          requisition_id
        }
      }
    }
  }
`;

export const GET_PROCUREMENT_MANAGER_DETAILS = gql`
query GetProcurementDepartment($getProcurementDepartmentId: Int) {
  getProcurementDepartment(id: $getProcurementDepartmentId) {
    parent_department
    department_name
    id
    user_id
  }
  }
`;

export const GET_ALL_CAPAPPROVERS = gql`
  query ListAllCapApprovers {
    listAllCapApprovers {
      id
      created_by
      deleted_at
      lower_limit
      user_id
      upper_limit
      description
      currency
      user_info {
        __typename
        full_name
      }
    }
  }
`;

export const FILTER_CAPAPPROVERS = gql`
  query FilterCapApprover($input: FilterCapApproverInput) {
    filterCapApprover(input: $input) {
      data {
        user_id
        id
        upper_limit
        lower_limit
        currency
        description
        deleted_at
        is_active
        user_info {
          user_id
          full_name
        }
      }
      count
    }
  }
`;

export const LIST_ALL_REQUISITION_STATUS = gql`
  query ListRequisitionStatus {
    listRequisitionStatus {
      id
      status
    }
  }
`;

export const LIST_STAFF_REQUISITION = gql`
  query ListStaffRequests($input: ListStaffRequestsInput) {
    listStaffRequests(input: $input) {
      role
      count
      data {
        id
        item_name
        reference_id
        request_status
        created_at
      }
    }
  }
`;

export const LIST_APPROVED_REQUEST = gql`
query ListApprovedRequests($input: ListApprovedRequestInput) {
  listApprovedRequests(input: $input) {
    role
    count
    data {
        id
        item_name
        reference_id
        request_status
        created_at
        requisition_status_id
    }
  }
}
`;

export const LIST_PROFORMA_SUMMARY_DOC = gql`
  query ListProcurementProformaInvoices($requisitionId: Int) {
    listProcurementProformaInvoices(requisitionId: $requisitionId) {
      id
      invoice_name
      invoice_url
      requisition_id
      supplier_id
      is_selected
    }
  }
`;
export const LIST_PROCUREMENT_SUMMARY_REPORT = gql`
  query GetProcurementSummaryReport($requisitionId: Int) {
    getProcurementSummaryReport(requisitionId: $requisitionId) {
      id
      report_name
      report_url
      proforma_invoice_id
      requisition_id
    }
  }
`;

export const GET_ALL_SUPPLIERS = gql`
  query ListSuppliers($pagination: PaginationInput!) {
    listSuppliers(pagination: $pagination) {
      count
      data {
        id
        supplier_name
        phone_number
        contact_person
        street_address
        business_reg_no
        business_tin_no
        is_tax_exempt
        business_description
        no_of_branches
        business_acc_name
        bank_branch
        swift_code
        bank_acct_no
        is_archived
        created_at
        created_by
        updated_at
        updated_by
        category
      }
    }
  }
`;
export const SEARCH_SUPPLIER = gql`
  query SearchSupplier($input: SupplierSearchInput) {
    searchSupplier(input: $input) {
      count
      data {
        id
        supplier_name
        phone_number
        contact_person
        supplier_email
        street_address
        business_reg_no
        business_tin_no
        is_tax_exempt
        business_description
        no_of_branches
        business_acc_name
        bank_branch
        swift_code
        bank_acct_no
        category
        is_archived
        created_at
        created_by
        updated_at
        updated_by
        documents {
          document_url
          document_name
          document_type
        }
      }
    }
  }
`;

export const GET_ALL_CATEGORIES = gql`
  query ListProcurementCategories {
    listProcurementCategories {
      id
      category_name
    }
  }
`;

export const LIST_PROCUREMENT_SUPPLIERS = gql`
  query ListProcurementSuppliers {
    listProcurementSuppliers {
      id
      supplier_name
    }
  }
`;
export const GET_SUPPLIER = gql`
  query getProcurementSupplier($supplierId: Int!) {
    getProcurementSupplier(supplierId: $supplierId) {
      id
      supplier_name
      phone_number
      supplier_email
      contact_person
      street_address
      business_reg_no
      business_tin_no
      is_tax_exempt
      business_description
      no_of_branches
      business_acc_name
      bank_branch
      swift_code
      bank_acct_no
      is_archived
      category
      category_id
      created_at
      created_by
      updated_at
      updated_by
      documents {
        document_url
        document_name
        document_type
      }
    }
  }
`;

export const LIST_CURRENCIES = gql`
  query ListCurrency {
    listCurrency {
      id
      type
    }
  }
`;

export const GET_PROCUREMENT_PURCHASE_ORDER = gql`
  query GetProcurmentPurchaseOrder($requisitionId: Int) {
    getProcurmentPurchaseOrder(requisitionId: $requisitionId) {
      ref_no
      requisition_id
      supplier_id
      item_requested
      id
    }
  }
`;


export const LIST_PROCUREMENT_USERS = gql`
query ListProcurementUsers {
  listProcurementUsers {
    full_name
    user_id
  }
}
`;
export const GET_PROCUREMENT_USER_ACCESS_LEVEL = gql`
query GetUserProcurementAccessLevel($userId: Int) {
  getUserProcurementAccessLevel(user_id: $userId) {
    permissions
  }
}
`;

export const LIST_PROCUREMENT_MANAGERS = gql`
query ListProcurementHopApprover {
  listProcurementHopApprover {
    user_id
    order
    user_info {
      full_name
    }
  }
}
`;