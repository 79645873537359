import { createApp, provide, h } from 'vue';
import App from './App.vue';

//Sentry Integration
// import * as Sentry from "@sentry/vue";
// import {BrowserTracing} from "@sentry/browser";
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import './registerServiceWorker';
import router from './router';
import './styles/app.css';
import './assets/global.scss';
import 'gitart-vue-dialog/dist/style.css';
import { GDialog } from 'gitart-vue-dialog';
import { getToken } from './services/auth';

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  concat,
  split
} from '@apollo/client/core';
import { ApolloClients } from '@vue/apollo-composable';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

// import CKEditor from "@ckeditor/ckeditor5-build-classic";
// import Vue from "vue";

// Vue.use(CKEditor);

// HTTP connection to the API
// });
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_API
});

const subscriptionUrl = process.env.VUE_APP_SUBSCRIPTION_URL ?? '';
const wsLink = new GraphQLWsLink(
  createClient({
    url: subscriptionUrl
  })
);

// Cache implementation
const cache = new InMemoryCache();

//Apollo Link for token
const auth: ApolloLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: 'Bearer ' + getToken() || null
    }
  }));

  return forward(operation);
});

const WsAndHttpLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

// Create the apollo client
const apolloClient = new ApolloClient({
  // link: concat(auth, httpLink),
  link: concat(auth, WsAndHttpLink),
  cache
});

const pinia = createPinia(); // for state management instead of vuex
pinia.use(piniaPluginPersistedstate);

const app = createApp({
  setup() {
    provide(ApolloClients, {
      default: apolloClient
    });
  },

  render: () => h(App)
});
const env = process.env.VUE_APP_ENVIRONMENT;

// Sentry.init({
//   app,
//   dsn: "https://7fd4a9446d7844f4bab1ad66afdce999@o1182568.ingest.sentry.io/4504077936295936",
//   environment: env,
//     integrations: [
//         new BrowserTracing({
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//             tracePropagationTargets: ["arms-beta.amalitech.org", /^\//],
//         }),
//     ],
//   logErrors: true,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampler: () => {
//     // Examine provided context data (including parent decision, if any) along
//     // with anything in the global namespace to compute the sample rate or
//     // sampling decision for this transaction

//     if (env === "production") {
//       // These are important - take a big sample
//       return 1.0;
//     } else if (env === "staging" || env === "testing") {
//       // These are less important or happen much more frequently - only take 1%
//       return 0.75;
//     } else if (env === "local") {
//       // These aren't something worth tracking - drop all transactions like this
//       return 0;
//     } else {
//       // Default sample rate
//       return 0.1;
//     }
//   },
//   // send only 100% of issues/errors in production.
//   sampleRate: process.env.VUE_APP_ISLOCAL ? 0.0 : 1.0
// });

app
  .use(pinia)
  .use(router)
  .component('GDialog', GDialog)
  .mount('#app');
