// Utility function to create a route
const createRoute = (path: string, name: string, chunkName: string, componentPath: string) => ({
  path,
  name,
  meta: { auth: true },
  component: () => import(/* webpackChunkName: chunkName */ `../views/${componentPath}.vue`)
});

export default [
  createRoute('/procurement-approvals', 'ProcurementApprovalsPage', 'procurement-approvals', 'ProcurementApprovalsPage'),
  createRoute('/procurement-requisition', 'RequisitionFormPage', 'procurement-requisition', 'RequisitionFormPage'),
  createRoute('/procurement-details/:id', 'RequisitionDetailsPage', 'procurement-details', 'RequisitionDetailsPage'),
  createRoute('/procurement-managers-dashboard', 'ManagersDashboardPage', 'procurement-managers-dashboard', 'ManagersDashboardPage'),
  createRoute('/procurement-suppliers', 'SuppliersPage', 'procurement-suppliers', 'SuppliersPage'),
  createRoute('/request-item/:id', 'RequestItemPage', 'requisiting-item', 'RequestItemPage'),
  createRoute('/requisitioning-steps-and-timelines', 'RequisitionPdfPage', 'requisition-policies', 'RequisitionPdfPage'),
  createRoute('/procurement-policies', 'ProcurementPoliciesPdfPage', 'procurement-policies', 'ProcurementPoliciesPdfPage'),
  createRoute('/create-supplier', 'CreateSuppliersPage', 'create-supplier', 'CreateSuppliersPage'),
  createRoute('/edit-supplier/:id', 'EditSuppliersPage', 'edit-supplier', 'EditSupplierPage'),
  createRoute('/procurement-manager-purchase-orders/:id', 'PurchaseOrder', 'procurement-manager-purchase-orders', 'HopPurchaseOrdersPage'),
  createRoute('/payment-requests/:id', 'PaymentRequests', 'payment-requests', 'PaymentRequestsPage'),
  createRoute('/settings', 'SettingsPage', 'config', 'SettingsPage'),
  createRoute('/create-access-level', 'CreateAccessLevelPage', 'addAccessLevel', 'CreateAccessLevelPage'),
  {
    path: '/help',
    name: 'help page',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "addAccessLevel" */ '../../../components/HelpPage.vue')
  },
];
