<template>
  <div
    class="w-full h-full flex justify-center items-center border bg-white p-12"
  >
    <div class="text-center [&>*]:py-1">
      <img src="../../../assets/404.svg" alt="404 page icon" />
      <h1 class="H800 N800">Page not found</h1>

      <p>
        Oops!<br />
        Seems like something went wrong...
      </p>
      <ButtonComponent
        :button-label="'Go back home'"
        :dataCy="'Go back home'"
        class="mx-auto my-2"
        @submit="handleGoTo('')"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import ButtonComponent from '@/ui-kit/button/ButtonComponent.vue';
import { useRouter } from 'vue-router';

const router = useRouter();
function handleGoTo(route: string) {
  const sanitizedRoute = route.replace(/^\//, '');
  if (route) router.push(`/${sanitizedRoute}`);
  else router.push('/');
}
</script>
