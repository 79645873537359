<template>
  <div class="z-50 flex">
   <div class="settings BG0" style="border: 1px solid #e6e8f0">
    <div class="flex items-center cursor-pointer relative">
      <a @click.prevent="routeUrl()" class="flex items-center">
      <div class="ml-2">
          <svg
            width="35"
            height="29"
            viewBox="0 0 41 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40.4" height="5.77143" rx="2.88571" fill="#DD5928" />
            <rect
              y="11.543"
              width="40.4"
              height="5.77143"
              rx="2.88571"
              fill="#DD5928"
            />
            <rect
              y="23.0859"
              width="40.4"
              height="5.77143"
              rx="2.88571"
              fill="#DD5928"
            />
          </svg>
      </div>
      <p class="
           ml-2
          text-[27px]
          N700
          font-bold
          sm:hidden
          md:hidden
          lg:block
          xl:block
        " v-if="store.showAll">
        AmaliTech
      </p></a>
      <span class="absolute h-6 w-6 rounded-full flex items-center py-0 px-2 shadow-md BG0 top-10 -right-5"
        @click="store.showAll = !store.showAll">
        <ArrowLeftIcon v-if="store.showAll" />
        <ArrowRightIcon v-else />
      </span>
    </div>

    <HorizontalLine />
      <div
        class="h-[80%] flex flex-col justify-between"
      >
        <div class="-ml-1">
         
          <div class="">
            <NavigationComponent
              :item="{ label: 'Dashboard', route: '/'}"
              @mouseover="toggleOnSettings(true, 'Home')"
              @mouseout="toggleOnSettings(false, 'Home')"
              @click="checkRoute('Home')"
            >
              <HomeRequisition
                :color="labels === 'Home' || active === 'Home'  ? '#DD5928' : '#8F95B2'"
              />
            </NavigationComponent>
          </div>
          <div class="">
            <NavigationComponent
            v-if="procAccess.viewYourRequisitions"
              :item="{
                label: 'Your Requisitions',
                route: '/procurement-requisition'
              }"
              @mouseover="toggleOnSettings(true, 'Your Requisitions')"
              @mouseout="toggleOnSettings(false, '')"
              @click="checkRoute('Your Requisitions')"
            >
              <YourRequisitionSVGVue
                :color="labels === 'Your Requisitions' || active === 'Your Requisitions' ? '#DD5928' : '#8F95B2'"
              />
            </NavigationComponent>
          </div>
          <div class="">
            <NavigationComponent
            v-if="procAccess.viewStaffRequisitions || procAccess.viewApprovedRequests || procAccess.viewApprovedRequests"
              :item="{
                label: 'Staff Requisitions',
                route: '/procurement-managers-dashboard'
              }"
              @mouseover="toggleOnSettings(true, 'Staff Requisitions')"
              @mouseout="toggleOnSettings(false, '')"
              @click="checkRoute('Staff Requisitions')"
            >
              <WalletSvgVue
                :color="labels === 'Staff Requisitions' || active === 'Staff Requisitions' ? '#DD5928' : '#8F95B2'"
              />
            </NavigationComponent>
          </div>
          <div class="">
            <NavigationComponent
            v-if="procAccess.viewSuppliers"
              :item="{
                label: 'Suppliers',
                route: '/procurement-suppliers'
              }"
              @mouseover="toggleOnSettings(true, 'Suppliers')"
              @mouseout="toggleOnSettings(false, '')"
              @click="checkRoute('Suppliers')"
            >
              <SuppliersSVG
                :color="labels === 'Suppliers' || active === 'Suppliers' ? '#DD5928' : '#8F95B2'"
              />
            </NavigationComponent>
        </div>

          <div>
            <HorizontalLine />
            <NavigationComponent
              v-if="accessStore.procurementAccessLevel.viewProcurementManager || 
                    accessStore.procurementAccessLevel.manageProcurementManager ||
                    settingsAccess.viewCapApprover ||
                    settingsAccess.manageCapApprover ||
                    settingsAccess.viewAccessLevel ||
                    settingsAccess.manageAccessLevel  
              "
              :item="{ label: 'Settings', route: '/Settings' }"
              @mouseover="toggleOnSettings(true, 'Settings')"
              @mouseout="toggleOnSettings(false, '')"
              @click="checkRoute('Settings')"
            >
              <SettingsSVG
                :color="labels === 'Settings' || active === 'Settings' ? '#DD5928' : '#8F95B2'"
              />
            </NavigationComponent>
          
          </div>
        
        </div>
      </div>
  </div>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits(['appSelected']);

import HomeRequisition from '@/assets/HomeRequisition.vue';
import WalletSvgVue from '@/assets/WalletSvg.vue';
import YourRequisitionSVGVue from '@/assets/YourRequisitionSVG.vue';
import SettingsSVG from '@/assets/settingsSVG.vue';
import SuppliersSVG from '@/assets/suppliersSVG.vue';
import { useStore } from '@/store/index';
import { ref, watch, watchEffect } from 'vue';
import HorizontalLine from './HorizontalLine.vue';
import NavigationComponent from './NavigationComponent.vue';

import { useProcurementViewAccessLevelStore } from '@/apps/procurement-service/store/store_procurement_access_levels';

import ArrowLeftIcon from '@/assets/ArrowLeftIcon.vue';
import ArrowRightIcon from '@/assets/ArrowRightIcon.vue';
import config from '@/config/config';
import { getToken } from "@/services/auth";
 import {settingsnavigation} from "../apps/procurement-service/procurement-store/procStore";

// user permissions
const accessStore = useProcurementViewAccessLevelStore();
const settingsAccess = useProcurementViewAccessLevelStore()?.settingsAccessLevels
const procurementAccessLevel = useProcurementViewAccessLevelStore()?.procurementAccessLevel
const settnavigate = settingsnavigation();
const store = useStore();
const active = ref<string>("");

watchEffect(()=>{

})

const loginHint: string = accessStore.loginHint;
const routeUrl = () =>{
  const token: string|null = getToken();
  if(token){
    window.location.href = `${config.mainPageLink}?app-token=${token}&login-hint=${loginHint}`;
    
  }
}

const onSettings = ref(false);
const labels = ref('');

function toggleOnSettings(status: boolean, label: string) {
  onSettings.value = status;
  if (status) {
    labels.value = label;
  } else {
    labels.value = '';
  }
}

const showSettings = ref(false);

const toggleSideBar = () => {
  showSettings.value = false;
  store.showAll = !store.showAll;
};
const procAccess = ref(accessStore.procurementAccessLevel);


watch(accessStore, (store)=>{
  if(store){
    procAccess.value = store.procurementAccessLevel
  }
}, {immediate: true});

const checkRoute = (param:string)=>{
 active.value = param;
}

</script>

<style scoped>
.settings {
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  padding: 20px 10px 0 23px;
  color: #474d66;
  background-color: white;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.settings::-webkit-scrollbar {
  display: none;
}

ul {
  margin: 19px 0;
}

li {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.H700 {
  z-index: 2;
}

@media screen and (min-width: 670px) and (max-width: 768px) {
  .menu {
    padding: 10px;
  }
}

@media screen and (max-width: 670px) {
  .menu {
    position: relative;
    top: 0;
    height: 40px;
    left: 5px;
    border: 1px solid #d8dae5;
    border-radius: 8px;
  }

  .menu_view {
    width: auto;
  }
}

@media (max-width: 670px) {
}
</style>
