import { ProcurementAccessLevelState } from '../interfaces/procurementAccessLevelTypes.util';

export const sharedState = (): ProcurementAccessLevelState => ({
  loginHint: "",
  basicInformation: {
    id: 0,
    accessLevelName: "",
    description: ""
  },
  settingsAccessLevels: {
    viewAccessLevel: false,
    manageAccessLevel: false,
    viewCapApprover: false,
    manageCapApprover: false,
  },
  procurementAccessLevel: {
    viewYourRequisitions: false,
    manageRequisitions: false,
    viewStaffRequisitions: false,
    manageStaffRequisitions: false,
    viewSuppliers: false,
    manageSuppliers: false,
    viewProcurementManager: false,
    manageProcurementManager: false,
    viewApprovedRequests: false,
    manageApprovedRequests: false,
  },
  userPermission: {},
  assigned_users: [],
});
